import React from "react";
import "pages/InternetService/InternetService.scss";
import TextMarquee from "components/Marquee/TextMarquee/TextMarquee";
import ContactInfo from "components/reusable/ContactInfo/ContactInfo";
import PageLink from "components/reusable/Link/PageLink/PageLink";
import Button from "components/reusable/Button/Button";
import HomeInternetBox from "components/reusable/HomeInternetBox/HomeInternetBox";
import { getQueries } from "api/wagtailAdapter";
import { usePagesApiParser } from "services/hooks/PagesAPIParser";
import { convertHtmlToReact } from "@hedgedoc/html-to-react/dist/convertHtmlToReact";
import { useLocation } from "react-router";
import { generateInternetPanels } from "components/reusable/Package/CreatePackages";
import { WagtailImage } from "components/Wagtail/WagtailImage";
import { processWagtailImage } from "components/Wagtail/WagtailImage";
import InternetServiceOverflowCarousel from "components/Carousel/InternetServiceOverflowCarousel/InternetServiceOverflowCarousel";
import ReviewCarousel from "components/Carousel/ReviewCarousel/ReviewCarousel";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AddressModal from "components/Modals/Address/Address";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import { toggleModal } from "store/reducers/modal/modalSlice";
import Head from "components/reusable/Head/Head";
import { LegalAccordion } from "../../components/SupportAccordion/SupportAccordion";
import {ControlledAccordion, useAccordionProvider} from "@szhsin/react-accordion";

const InternetService = () => {
  const {
    page: info,
  } = usePagesApiParser(
    getQueries(useLocation().search).useGetServiceInternetPageQuery({
      queryParams: useLocation().search,
      localePath: useLocation().pathname.split("/")[1],
      region: useLocation().pathname.split("/")[2],
    })
  );
  const {
    header,
    subtitle,
    main_image,
    overlay_image,
    use_black_friday_promo_layout,
    black_friday_mobile_image,
    asterisk_text_for_promo,
  } = info?.main_carousel[0]?.value || {};
  const { region } = useSelector((state) => state.localizationSlice);

  const dispatch = useDispatch();

  const accordionValue = useAccordionProvider({
    allowMultiple: false,
    transition: true,
    transitionTimeout: 200,
  });

  const { toggle } = accordionValue;
  const [openAccordion, setOpenAccordion] = React.useState(false);

  const handleAccordionOpen = (data) => {setOpenAccordion(data);};
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const modalContent = () => {
    return (
      <>
        <div className="internet-header-title">
          <h1>{convertHtmlToReact(header)}</h1>
          <p className="internet-header-subtitle">
            {convertHtmlToReact(subtitle)}
          </p>
        </div>
        <div className="internet-banner-image">
          <div className={use_black_friday_promo_layout ? "main-image black-friday-promo" : "main-image"} style={{
            backgroundImage: `url(${processWagtailImage(
              main_image
            )})`,
          }}>
            {use_black_friday_promo_layout ? (
              <div className="asterisk-text">
                {convertHtmlToReact(asterisk_text_for_promo)}
              </div>
            ) : null}
          </div>
          <div className={use_black_friday_promo_layout ? "overlay-image black-friday-promo" : "overlay-image"}>
            <WagtailImage image={overlay_image}/>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Head
        title={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Beanfield service Internet par fibre optique" :
          "Beanfield Fibre Internet"
        }
        description={
          useLocation().pathname.split("/")[1] === "fr" ?
          "Internet par fibre ultra-rapide et illimité à partir de 58$/mois. Fourni sur notre propre réseau en fibre. Jouez, diffusez et travaillez à domicile avec une fiabilité exceptionnelle." :
          "Ultra-fast, unlimited fibre Internet starting at $58/mo. Delivered over our own fibre network. Game, stream and work from home with outstanding reliability."
        }
        imageName={"Internet"}
        locale={useLocation().pathname.split("/")[1]}
      />
      <div className="resi-container">
        <Breadcrumbs route={"internet"}></Breadcrumbs>
      </div>
      <div>
        <div className={use_black_friday_promo_layout ? "internet-banner-background black-friday-promo" : "internet-banner-background"}>
          <div className="resi-container">
            <div className="resi-margin-container">
              <div className={use_black_friday_promo_layout ? "internet-banner-container black-friday-promo" : "internet-banner-container"}>
                {modalContent()}
                {use_black_friday_promo_layout ? (
                  <>
                    <div className="mobile-promo-image">
                      <div className="image">
                        <WagtailImage image={black_friday_mobile_image}/>
                      </div>
                    </div>
                    <div className="asterisk-text">
                      {convertHtmlToReact(asterisk_text_for_promo)}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* INTERNET PLANS */}
        {info?.internet_pricing_box.length === 0 ? (
          <div className="internet-select-container resi-container">
            <div className="resi-margin-container">
              {info?.plans && generateInternetPanels(info.plans)}
            </div>
          </div>
        ) : (
          <div className="resi-container">
            <div className="resi-margin-container">
              <div className="internet-speeds-container">
                <div className="internet-speeds-header">
                  <h2>{info?.internet_pricing_box_header}</h2>
                </div>
                <div
                  className={info?.internet_pricing_box.length > 3 ? "internet-speeds-inner flash-sale" : "internet-speeds-inner"}>
                  {(use_black_friday_promo_layout && width < 1025) ? (
                    <>
                      {info?.internet_pricing_box.reduce((acc, element) => {
                        if (element.use_promo) {
                          return [element, ...acc];
                        }
                        return [...acc, element];
                      }, []).map((pricingData, index) => {
                        return <HomeInternetBox data={pricingData} index={index} callback={(e) => handleAccordionOpen(e)}/>
                      })}
                    </>
                  ) : (
                    <>
                      {info?.internet_pricing_box.map((pricingData, index) => {
                        return <HomeInternetBox data={pricingData} index={index} callback={(e) => handleAccordionOpen(e)}/>
                      })}
                    </>
                  )}

                </div>
              </div>
            </div>
          </div>
        )}

        {/* INTERNET BADGES */}
        <div className="resi-container">
          <div className="resi-margin-container">
            <div className="internet-badges-container">
              {info?.feature_badges.map((badge) => {
                return (
                  <div>
                    <WagtailImage height={110} image={badge?.image}/>
                    <p>{convertHtmlToReact(badge.text)}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* MARQUEE COMPONENT */}
        <div className="internet-marquee-container">
          {info?.text_wheel_text_a || info?.text_wheel_text_b ? (
            <TextMarquee
              textOne={info?.text_wheel_text_a}
              textTwo={info?.text_wheel_text_b}
              colorPrimary={info?.text_wheel_colour_a}
              colorSecondary={info?.text_wheel_colour_b}
            />
          ) : null
          }
          <div className="resi-container">
            <div className="resi-margin-container">
              <div className="internet-marquee-text-container">
                {convertHtmlToReact(info?.why_us_description)}
                <PageLink bold arrow={2} target="_blank" to={info?.why_us_link_url}>
                  {info?.why_us_link}
                </PageLink>
              </div>
              {info?.why_us_image ? (
                <div className="internet-marquee-image-container">
                  <WagtailImage
                    image={info?.why_us_image}
                    alt={info?.why_us_image?.title}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {/* INTERNET CAROUSEL */}
        <InternetServiceOverflowCarousel
          header={info?.secondary_carousel_header}
          description={info?.secondary_carousel_description}
          items={info?.secondary_carousel_items}
        />

        {/* TIED TO ANOTHER PROVIDER */}
        {region !== "bc" ? (
          <div className="resi-container">
            <div className="resi-margin-container">
              <div className="internet-provider-container">
                <div className="internet-provider-content">
                  <h2>{info?.conversion_header}</h2>

                  <p>{info?.conversion_description}</p>

                  <Button
                    click={() => {
                      dispatch(toggleModal({ children: <AddressModal /> }));
                    }}
                  >
                    {info?.conversion_btn}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* INTERNET PACKAGES */}
        <div className="resi-container">
          <div className="resi-margin-container">
            <div className="internet-package-container">
              {info?.info_boxes?.map((box, index) => {
                if (region === "bc" && index > 0) return null;
                return (
                  <div className="internet-package">
                    <div className="internet-package-text">
                      <h3>{box.header}</h3>
                      <p>{box.body}</p>
                      <PageLink bold arrow={"true"} to={box.link}>
                        {box.link_text}
                      </PageLink>
                    </div>

                    <WagtailImage image={box?.image} alt={box?.image?.title} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* AVAILABILITY MAP */}
        {info?.availability_map_header ? (
          <div className="internet-availability-map-container">
            <div className="resi-container">
              <div className="resi-margin-container">
                <div className="internet-availability-map-content">
                  <div className="left-content">
                    {convertHtmlToReact(info?.availability_map_header)}
                    {convertHtmlToReact(info?.availability_map_description)}

                    <PageLink to={info?.availability_map_link} bold arrow={"true"}>
                      {info?.availability_map_link_text}
                    </PageLink>
                  </div>
                  <div className="right-content">
                    <WagtailImage image={info?.availability_map_image} alt={info?.availability_map_image?.title} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          ) : null }

        {/* CUSTOMER REVIEWS */}
        <div className="internet-review-container">
          <div className="internet-review-headers">
            <h2>{info?.reviews_header}</h2>
            <p>{info?.reviews_subtitle}</p>
          </div>
          {info?.reviews?.length > 0 && (
            <ReviewCarousel internetService review items={info?.reviews} />
          )}
        </div>

        {/* CONTACT US */}
        {info?.contact_us_page?.badges?.length > 0 && (
          <ContactInfo
            title={info.contact_us_page.title}
            description={info.contact_us_page.description}
            badges={info.contact_us_page.badges}
          />
        )}

        {/* LEGAL TAGLINE */}
        {info?.offer?.show_offer_details?
          <div className="resi-container">
            <div className="resi-margin-container">
              <div className="internet-legal-tagline-container">
                <ControlledAccordion providerValue={accordionValue}>
                  <LegalAccordion title={info?.offer?.offer_details_title} body={info?.offer?.offer_details_body} toggle={toggle} initialEntered={openAccordion}/>
                </ControlledAccordion>
              </div>
            </div>
          </div>: null
        }
      </div>
    </>
  );
};

export default InternetService;
